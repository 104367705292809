<template>
    <img :src="img_url" v-if="img_url" @click="exchange_img">
</template>

<script>
  import {get_verify} from "@net/img_verify";
  //const Base64 = require('js-base64').Base64
  export default {
    name: "img_verify",
    data(){
      return{
        img_url:''
      }
    },
    created() {
      this.getCaptcha()
    },
    methods: {
      getCaptcha() {
        get_verify().then(res => {
          this.img_url = 'data:image/png;base64,' + window.btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
        })
      },
      /*_arrayBufferToBase64( buffer ) {//arrayBuffer 转base64
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] );
        }
        this.img_url='data:application/pdf;base64,'+ window.btoa( binary );
      }*/
      exchange_img(){
        this.getCaptcha()
      }
    }
  }
</script>

<style scoped>

</style>