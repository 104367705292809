<template>
  <img class="mt30 margin-auto logo" src="https://www.lmlg.top/static/img/public/logo.png" />
  <div class="mt30 wa90">
    <el-form label-position="left" label-width="60px">
      <el-form-item label="账号">
        <el-input v-model="account" placeholder="请输入邮箱/账号"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="password" :minlength="6" show-password placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item label="验证码">
        <div class="flex verify-dom">
          <el-input v-model="verify_code" placeholder="请输入验证码"></el-input>
          <img_verify class="ml10"></img_verify>
        </div>

      </el-form-item>
    </el-form>
    <div class="login-btn font14 mt20 text-center" @click="login">登录</div>

    <div class="between mt10">
      <div class="font14">
        没有账号?&nbsp;去<router-link class="jump-style font14" to="/register_email" replace>注册</router-link>
      </div>
      <router-link class="jump-style font14" to="/register_email" replace>忘记密码</router-link>
    </div>
  </div>
</template>

<script>
  import {login_password} from "@net/login/login";
  import LocalStorage from '@/localStorage/localstorage'
  import Img_verify from "../../components/img_verify";
  export default {
    name: "login",
    components: {Img_verify},
    data(){
      return{
        account:'',
        password:'',
        verify_code:''
      }
    },
    created() {
      if(this.Utils.has_login()){
        this.Utils.login_back()
      }
      LocalStorage.remove_val('openid')
    },
    methods:{
      login(){
        if(this.password==''){
          this.Utils.Toast('error','请输入密码')
          return
        }
        if(this.account==''){
          this.Utils.Toast('error','请输入账号/邮箱')
          return
        }
        let rule = {
          'password': '请输入密码',
          'account': '请输入账号',
          'verify_code': '请输入验证码',
        };
        let obj = {};
        let arr = ['password', 'account', 'verify_code'];
        for (let i = 0; i < arr.length; i++) {
          if (this[arr[i]] == '') {
            if (rule.hasOwnProperty(arr[i])) {
              this.$toast(rule[arr[i]]);
            }
            return false;
          } else {
            obj[arr[i]] = this[arr[i]];
          }
        };
        this.Utils.debounce(() => {
          login_password(obj).then(res => {
            if (res.business_code == 200) {
              LocalStorage.save('openid', res.data.openid);
              this.Utils.Toast('success', '登录成功');
              this.Utils.login_back();
            } else {
              this.Utils.Toast('error', res.msg);
            }
          })
        });
      }
    }
  }
</script>

<style scoped>
  .logo{
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .jump-style{
    border: none;
    color: #ea5503;
    text-decoration:none
  }
  .login-btn{
    color: white;
    background-color: #ea5503;
    line-height: 40px;
    height: 40px;
    border-radius: 5px;
  }
  .verify-dom ::v-deep(.el-input){
    width: 150px;
  }
</style>